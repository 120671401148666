import React from "react";
import "./Resume.css";
import { Grid, Typography } from "@material-ui/core";
import resumeData from "../../utils/resumeData";
import CustomTimeline, {
  CustomTimelineSeparator,
} from "../../components/main/timeline/Timeline";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import { TimelineContent, TimelineDot, TimelineItem } from "@material-ui/lab";
import Paper from "@mui/material/Paper";

const Resume = () => {
  return (
    <>
      {/* Education and Experience */}
      <Grid container className="section pb-30 pt-45">
        <Grid item className="section-title bottom-30">
          <span></span>
          <h6 className="section-title-text">Resume</h6>
        </Grid>
        <Grid item xs={12}>
          <Grid container className="resume-timeline">
            {/* Work History */}
            <Grid item sm={12} md={6}>
              <CustomTimeline title={"Work Experience"} icon={<WorkIcon />}>
                {resumeData.workExperience.map((experience) => (
                  <TimelineItem>
                    <CustomTimelineSeparator />
                    <TimelineContent className="timeline-content">
                      <Typography className="timeline-title">
                        {experience.title}
                      </Typography>
                      <Typography variant="caption" className="timeline-date">
                        {experience.dateRange}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="timeline-description"
                      >
                        {experience.description}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </CustomTimeline>
            </Grid>
            {/* Education */}
            <Grid item sm={12} md={6}>
              <CustomTimeline title={"Education"} icon={<SchoolIcon />}>
                {resumeData.education.map((education) => (
                  <TimelineItem>
                    <CustomTimelineSeparator />
                    <TimelineContent className="timeline-content">
                      <Typography className="timeline-title">
                        {education.title}
                      </Typography>
                      <Typography variant="caption" className="timeline-date">
                        {education.dateRange}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="timeline-description"
                      >
                        {education.description}
                      </Typography>
                      {/* <TimelineItem className="timeline-sub-section">
                        <CustomTimelineSeparator />
                        <div className="timeline-sub-container">
                          <Typography
                            variant="body2"
                            className="timeline-sub-item-title"
                          >
                            {education.subTitle}
                          </Typography>
                          <Typography
                            variant="caption"
                            className="timeline-sub-item-text"
                          >
                            {education.subText}
                          </Typography>
                        </div>
                      </TimelineItem> */}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </CustomTimeline>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Skills */}
      <Grid container className="section graybg pb-45 p-50">
        <Grid item className="section-title bottom-60">
          <span></span>
          <h6 className="section-title-text">Skill Sets</h6>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="space-around">
            {resumeData.skills.map((skill) => (
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={0} className="skill">
                  <Typography className="skill-title">{skill.title}</Typography>
                  {skill.description.map((element) => (
                    <Typography variant="body2" className="skill-description">
                      <TimelineDot variant="outlined" className="skill-dot" />
                      {element}
                    </Typography>
                  ))}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Resume;
