import resumeData from "../../../utils/resumeData";
import { Link, NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav } from "react-bootstrap";
import CustomButton from "../button/Button";

import TelegramIcon from "@mui/icons-material/Telegram";
import HomeIcon from "@mui/icons-material/Home";
import "./Header.css";

const Header = (props) => {
  const pathName = "props?.location?.pathname";

  return (
    <Navbar expand="lg" sticky="top" className="header">
      <Link to="/" as={NavLink} className="header-navlink">
        <Navbar.Brand className="header-home">
          <HomeIcon />
        </Navbar.Brand>
      </Link>

      <Nav className="header-left">
        {/* Resume Link */}
        <Nav.Link
          as={NavLink}
          to="/"
          className={pathName === "/" ? "header-link-active" : "header-link"}
        >
          About Me
        </Nav.Link>
        {/* Resume Link */}
        <Nav.Link
          as={NavLink}
          to="/resume"
          className={pathName === "/" ? "header-link-active" : "header-link"}
        >
          Resume
        </Nav.Link>
        {/* Portfolio Link */}
        <Nav.Link
          as={NavLink}
          to="/portfolio"
          className={
            pathName === "/portfolio" ? "header-link-active" : "header-link"
          }
        >
          {console.log(pathName)}
          Portfolio
        </Nav.Link>
      </Nav>
      <div className="header-right">
        {Object.keys(resumeData.socials).map((key) => (
          <a
            href={resumeData.socials[key].link}
            target="_blank"
            rel="noreferrer"
          >
            {resumeData.socials[key].icon}
          </a>
        ))}
        <div className="hiremeButton">
          <CustomButton
            text={"Hire Me"}
            href="mailto:evblankenheim@gmail.com"
            icon={<TelegramIcon />}
          />
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
