import React from "react";
import Gallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import "./ImageGallery.css";

const ImageGallery = (props) => {
  const imageList = props?.images?.map((image) => {
    return {
      original: image,
      thumbnail: image,
    };
  });

  return (
    <div>
      <Gallery
        items={imageList}
        showBullets
        showThumbnails={props.images?.length > 1 ? true : false}
      />
    </div>
  );
};

export default ImageGallery;
