import { Container, Grid } from "@material-ui/core";
import Profile from "./components/main/profile/Profile";
import Header from "./components/main/header/Header";
import Footer from "./components/main/footer/Footer";
import Portfolio from "./pages/portfolio/Portfolio";
import Resume from "./pages/resume/Resume";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";

function App() {
  return (
    <Container className="top-60">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Profile />
        </Grid>
        <Grid item xs>
          <Header />
          <div className="main-content container-shadow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/portfolio" element={<Portfolio />} />
            </Routes>
          </div>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
