import Axios from "axios";
import { useState } from "react";
import "./Pokemon.css";

function Pokemon() {
  const [num, setNum] = useState(4);
  const [pokemonName, setPokemonName] = useState("Charmander");
  const [imgUrl, setImgUrl] = useState(
    "https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/4.svg"
  );

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const handleClick = () => {
    setNum(randomNumberInRange(1, 1000));
    Axios.get(`https://pokeapi.co/api/v2/pokemon/${num}`).then((res) => {
      let pn = res.data.species.name;
      let pn1 = pn.charAt(0).toUpperCase() + pn.slice(1);
      setPokemonName(pn1);

      if (res.data.sprites.front_default != null) {
        setImgUrl(res.data.sprites.front_default);
      }
      if (res.data.sprites.other.home.front_default != null) {
        setImgUrl(res.data.sprites.other.home.front_default);
      }
      if (res.data.sprites.other.dream_world.front_default != null) {
        setImgUrl(res.data.sprites.other.dream_world.front_default);
      }
    });
  };

  // const pokemonList = await fetch("https://pokeapi.co/api/v2/pokemon/7");
  // const result = await pokemonList.json();
  // return result.forms[0].name;
  //   let randNum = randomNumber(0, 1200);
  //   let randomPokemon = returnPokemonByIndex(randNum);
  //   console.log(randomPokemon);

  return (
    <div className="game">
      <img className="pokemonImage" src={imgUrl} alt={pokemonName}></img>
      <h3 className="pokemonName">{pokemonName}</h3>
      <button className="button-Pokemon" onClick={handleClick}>
        Generate Pokemon
      </button>
    </div>
  );
}

export default Pokemon;
