import React from "react";
import "./Home.css";
import { Grid, Typography, Icon } from "@material-ui/core";
import resumeData from "../../utils/resumeData";

const Home = () => {
  return (
    <>
      {/* About me */}
      <Grid container className="section pb-45 pt-45">
        <Grid item className="section-title bottom-30">
          <span></span>
          <h6 className="section-title-text">About Me</h6>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className="aboutme-text">
            {resumeData.about}
          </Typography>
        </Grid>
      </Grid>

      {/* Services */}
      <Grid container className="section pb-45">
        <Grid item className="section-title bottom-30">
          <span></span>
          <h6 className="section-title-text">What I do</h6>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="space-around">
            {resumeData.services.map((service) => (
              <Grid item xs={12} sm={6} md={3}>
                <div className="service">
                  <Icon className="service-icon">{service.icon}</Icon>
                  <Typography className="service-title" variant="h6">
                    {service.title}
                  </Typography>
                  <Typography className="service-description" variant="body2">
                    {service.description}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
