import React from "react";
import { Typography } from "@material-ui/core";
import "./Footer.css";
import resumeData from "../../../utils/resumeData";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-left">
        <Typography className="footer-name">{resumeData.name}</Typography>
      </div>
      <div className="footer-right">
        
        {Object.keys(resumeData.socials).map((key) => (
          <a
            href={resumeData.socials[key].link}
            target="_blank"
            rel="noreferrer"
          >
            {resumeData.socials[key].icon}
          </a>
        ))}
        {/* <Typography className="footer-copyright">
          Developed by Evan Blankenheim
          <br />
          Designed by{" "}
          <a
            href="https://www.youtube.com/@SalmanFazal01"
            target="_blank"
            rel="noreferrer"
          >
            Salman Fazal
          </a>
        </Typography> */}
      </div>
    </div>
  );
};

export default Footer;
