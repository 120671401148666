import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CustomButton = ({ text, icon, href }) => {
  return (
    <ColorButton
      component="label"
      variant="contained"
      classname="custom_btn"
      endIcon={icon}
      onClick={(e) => {
        window.location.href = href;
        e.preventDefault();
      }}
    >
      <span className="btn-text">{text}</span>
    </ColorButton>
  );
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#dd9c42"),
  backgroundColor: "#dd9c42",
  "&:hover": {
    backgroundColor: "#dd9c42",
  },
}));

export default CustomButton;
