import React from "react";
import "../resume/Resume.css";
import {
  Card,
  CardMedia,
  Tab,
  Tabs,
  CardActionArea,
  Grid,
  CardContent,
  Typography,
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useState } from "react";
import resumeData from "../../utils/resumeData";
import "./Portfolio.css";
import ImageGallery from "../../components/imageGallery/ImageGallery";
import CustomButton from "../../components/main/button/Button";

const Portfolio = () => {
  const [tabValue, setTabValue] = useState("All");
  const [projectDialog, setProjectDialog] = useState(false);

  return (
    <>
      {/* Portfolio */}
      <Grid container className="section pb-45 pt-45">
        {/* title */}
        <Grid item className="section-title bottom-20">
          <span></span>
          <h6 className="section-title-text">Portfolio</h6>
        </Grid>

        {/* Tabs */}
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            indicatorColor="white"
            className="customTabs bottom-30"
            textColor="black"
            onChange={(tabEvent, newValue) => setTabValue(newValue)}
          >
            <Tab
              label="All"
              value="All"
              className={
                tabValue === "All"
                  ? "customTabs-item active"
                  : "customTabs-item"
              }
            />
            {[...new Set(resumeData.projects.map((item) => item.tag))].map(
              (tag) => (
                <Tab
                  label={tag}
                  value={tag}
                  className={
                    tabValue === tag
                      ? "customTabs-item active"
                      : "customTabs-item"
                  }
                />
              )
            )}
          </Tabs>
        </Grid>

        {/* projects */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {resumeData.projects.map((project) => (
              <>
                {tabValue === project.tag || tabValue === "All" ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <Grow in timeout={1000}>
                      <Card
                        className="customCard"
                        onClick={() => setProjectDialog(project)}
                      >
                        <CardActionArea>
                          <CardMedia
                            className="customCard-image"
                            image={project.image}
                            title={project.title}
                          />
                          <CardContent>
                            <Typography
                              variant={"body1"}
                              className="customCard-title"
                            >
                              {project.title}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="customCard-caption"
                            >
                              {project.caption}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grow>
                  </Grid>
                ) : null}
              </>
            ))}
          </Grid>
        </Grid>
        <Dialog
          open={projectDialog}
          onClose={() => setProjectDialog(false)}
          maxWidth={"lg"}
          fullWidth
          className="projectDialog"
        >
          <DialogTitle
            onClose={() => setProjectDialog(false)}
            className="projectDialog-title"
          >
            <Typography variant="h6">{projectDialog.title}</Typography>
            <Typography>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setProjectDialog(false)}
                className="projectDialog-closeButton"
                href={null}
              >
                X
              </a>
            </Typography>
          </DialogTitle>
          <DialogContent>
            {projectDialog.tag === "App"
              ? projectDialog.app
              : projectDialog.images && (
                  <ImageGallery images={projectDialog.images} />
                )}
            <Typography variant="body2" className="projectDialog-description">
              {projectDialog.description}
            </Typography>
          </DialogContent>
          <DialogActions className="projectDialog-actions">
            {projectDialog?.links?.map((link) => (
              <CustomButton
                text={link.text}
                icon={link.icon}
                href={link.link}
              />
            ))}
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default Portfolio;
